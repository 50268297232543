import unauthedLayoutContent from './unauthed-layout-content.yml';
import acpUnauthedLayout from './acp-unauthed-layout-component';
import acpComponentEnvironmentChooser from 'components/environment-chooser';
import acpComponentAcquisitionDomain from 'components/acquisition-domain';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
var component = ng
  .module('acp.component.unauthed-layout', [
    acpCore.name,
    acpComponentAcquisitionDomain.name,
    acpComponentEnvironmentChooser.name
  ])
  .component('acpUnauthedLayout', acpUnauthedLayout)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/unauthed-layout',
        unauthedLayoutContent
      );
    }
  );

export default component;
