import acpUnauthedTemplate from './templates/acp-unauthed.html';
import ng from 'angular';

// @ngInject
function AcpUnauthedLayoutCtrl(
  acpAcquisitionModel,
  acpApiBrandingDomainModel,
  acpBrandingClient,
  acpConfigurationService,
  acpCoreDispatcher,
  acpDeviceDetectionService,
  acpDeviceService,
  acpPreviousLoginModel,
  acpAuthModel,
  content,
  nsComponentDecorator,
  $anchorScroll,
  $location,
  $state,
  $window
) {
  var vm = nsComponentDecorator(this);

  vm.branding = acpBrandingClient.get();

  vm.$tiePermissions('permissions', [
    'isAltActivationMessaging',
    'showMobileDisclosuresAtTheEnd'
  ]);

  vm.$onInit = function () {
    vm.$tie('apiBranding', acpApiBrandingDomainModel.apiBranding);

    vm.isBackButtonEnabled = $state.params.backButtonEnabled;

    vm.$onValue(
      acpCoreDispatcher.unauthedLayout.disableBackButton,
      function () {
        vm.isBackButtonEnabled = false;
      }
    );

    vm.$onValue(acpCoreDispatcher.activation.accessTokenReady, function () {
      vm.hasActivatedAndAuthed = true;

      // Reload the logo after an activation (but not during login)
      vm.$onValue(content.contentRefresh, loadLogoCssClass);
    });

    vm.logoOverride = 'default';
    loadLogoCssClass();
  };

  vm.getCategory = function () {
    switch ($state.current.name) {
      case 'login.main':
        return 'login';
      case 'recover.username':
        return 'ForgotUsername';
      case 'recover.password':
        return 'ForgotPassword';
      case 'public.help.contact':
        return 'contactUsPreLogin';
      case 'public.activate':
      case 'public.register':
        return 'activation-register';
    }
  };

  vm.getLabel = function () {
    switch ($state.current.name) {
      case 'login.main':
        return 'acp-login';
      case 'recover.username':
        return 'acp-forgot-username';
      case 'recover.password':
        return 'acp-forgot-password';
      case 'public.help.contact':
        return 'acp-pre-login-contact';
      case 'public.activate':
      case 'public.register':
        return 'acp-activation';
    }
  };

  vm.getRoute = function () {
    return $state.current.name;
  };

  vm.isCordova = function () {
    return acpDeviceService.isCordova();
  };

  vm.scrollToLinks = function () {
    $location.hash('additional-info-link');
    $anchorScroll();
  };

  vm.login = function () {
    // Make sure we are clean and then redirect back to login
    acpAuthModel.logout();
  };

  vm.programType = acpConfigurationService.getProgramType();
  vm.tenantBrand = acpConfigurationService.getBrand();
  vm.marketingSiteLinkText = acpConfigurationService.getMarketingSiteLinkText();
  vm.marketingSiteUrl = acpConfigurationService.getMarketingSiteUrl();

  vm.locationsDestination = function () {
    if (vm.programType === 'gpr') {
      $state.go('locations');
    } else {
      content.getKey('reload-locations', 'core/links').then(function (data) {
        $window.location.href = data.copy;
      });
    }
  };

  vm.showAcquisitionLink = function () {
    return (
      vm.getRoute() !== 'login.main' &&
      acpConfigurationService.showAcquisitionLink()
    );
  };

  vm.showActivateLink = function () {
    return (
      vm.getRoute() !== 'public.activate' && vm.getRoute() !== 'public.register'
    );
  };

  vm.showRegisterLink = function () {
    return (
      vm.getRoute() === 'login.main' &&
      acpConfigurationService.showRegisterLink()
    );
  };

  vm.showAppsLink = function () {
    return (
      vm.isCordova() ||
      vm.programType === 'corp_disbursement' ||
      !vm.apiBranding ||
      vm.programType === 'disbursement'
    );
  };

  vm.showMarketingLink = function () {
    return acpConfigurationService.showMarketingSiteLink();
  };

  vm.showLocationsLink = function () {
    var showLink = false,
      programTypes = [
        'consumer_dda',
        'corp_disbursement',
        'paycard',
        'expense_management',
        'disbursement'
      ];
    if (vm.programType && programTypes.indexOf(vm.programType) === -1) {
      if (
        (acpDeviceService.isWeb() &&
          acpConfigurationService.showLocationsLink()) ||
        !acpDeviceService.isWeb()
      ) {
        showLink = true;
      }
    }

    return showLink;
  };

  vm.acquireCustomer = acpAcquisitionModel.acquireNewCustomer;

  vm.hasPreviouslyLoggedIn = function () {
    return acpPreviousLoginModel.hasPreviouslyLoggedIn();
  };

  vm.showNetspendFreeAtmFinder = function () {
    return acpConfigurationService.isUnAuthedNetspendFeeFreeAtmsEnabled();
  };

  vm.goToCustomerService = function () {
    acpCoreDispatcher.routing.customerService.goTo.emit();
    $state.go('public.help.contact');
  };

  vm.environment = acpConfigurationService.getEnvironment();
  vm.appVersion = acpConfigurationService.getVersion();

  // TODO: @dbartlett this needs to be stripped completely from build...
  vm.showEnvChooser = function () {
    if (vm.environment === 'prod' || !vm.isCordova()) {
      return;
    }

    ng.element(document.querySelector('acp-env-chooser')).css(
      'display',
      'block'
    );
  };

  //TODO: Once MLB has its marketing site, this can be removed and the conditional anchor tag for LOGO also.
  vm.showLogoWithoutMarketingLink = function () {
    return (
      ['mlb-theshow-prepaid', 'austinfc'].includes(vm.tenantBrand) &&
      !vm.hideAppBarLogo
    );
  };

  vm.showLogoWithMarketingLink = function () {
    return (
      vm.tenantBrand !== 'austinfc' &&
      vm.tenantBrand !== 'mlb-theshow-prepaid' &&
      !vm.hideAppBarLogo
    );
  };

  vm.clickBackButton = function () {
    document.cookie = 'ACP_NG=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    $window.history.back();
  };

  function loadLogoCssClass() {
    content
      .getKey('logo-override', 'components/unauthed-layout')
      .then(function (data) {
        vm.logoOverride = data.copy;
      });
  }

  vm.altColorsForActivation = function () {
    return vm.getRoute() === 'public.activate' &&
      vm.permissions &&
      vm.permissions.isAltActivationMessaging
      ? 'activate-alternate'
      : '';
  };

  return this;
}

var acpUnauthedLayout = {
  controller: AcpUnauthedLayoutCtrl,
  bindings: {
    hideAppBarLogo: '='
  },
  template: acpUnauthedTemplate,
  transclude: {
    'caption-header': '?pageCaptionHeader',
    title: 'pageTitle',
    subtitle: '?pageSubtitle',
    'card-content': '?pageCardContent',
    'multi-card-content': '?pageMultiCardContent',
    'extra-content': '?pageExtraContent',
    'styleless-content': '?pageStylelessContent',
    'custom-disclosures': '?pageCustomDisclosures'
  }
};

export default acpUnauthedLayout;
